import axios from 'axios';

export const SET_TUTORIAL_INFORMATION = 'SET_TUTORIAL_INFORMATION';
export const SET_TUTORIAL_FAQ_URLS = 'SET_TUTORIAL_FAQ_URLS';

export const setTutorialInformation = (data) => ({ type: SET_TUTORIAL_INFORMATION, data })
export const setTutorialUrl = (data) => ({ type: SET_TUTORIAL_FAQ_URLS, data })

export const getTutorialUrl = (id) => (dispatch) => {


    axios.get(`/back-hia/api/v1/conventions/ack/${id}/tuto-faq-details`)
        .then((response) => {
            if (response.status === 200) {
                dispatch(setTutorialUrl(response.data))
            }
        }).catch(function (error) {
            console.log('error')
        })
}

export const loadTutorialInformation = (id) => (dispatch) => {

    axios.put(`/back-hia/api/v1/conventions/tuto?tutoToken=${id}`, {
            headers: {
                'Content-Type': 'application/json',
                'Acces-Control-Allow-Origin': '*'
            },

        }).then((response) => {
            console.log("getting tutorial information response :", response);
            if (response.status === 200) {
                dispatch(setTutorialInformation(response.data));
            }
        }).catch(function (error) {
            console.log("error while getting tutorial information :", error);
            // dispatch(setLoginError(error.response.data));
        })
}

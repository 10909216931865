import axios from 'axios';
import { setLoadingStatus } from '.';


export const SET_CLIENT_LIST = 'SET_CLIENT_LIST';
export const SET_CLIENTS_TYPE_STATS = 'SET_CLIENTS_TYPE_STATS';
export const SET_CLIENT = 'SET_CLIENT';
export const SET_CLIENT_USERS = 'SET_CLIENT_USERS';
export const SET_CLIENT_ID = 'SET_CLIENT_ID';
export const SET_CLIENT_CONVENTIONS = 'SET_CLIENT_CONVENTIONS';
export const SET_CLIENT_USERS_COUNT = 'SET_CLIENT_USERS_COUNT';
export const SET_CLIENT_NOTES = 'SET_CLIENT_NOTES';
export const SET_ClIENT_OPEN_ACCOUNTS = 'SET_ClIENT_OPEN_ACCOUNTS';
export const SET_CLIENT_KPI_STATISTICS = 'SET_CLIENT_KPI_STATISTICS';
export const SET_CLIENT_ERROS = 'SET_CLIENT_ERROS';

export const setClientList = (data) => ({ type: SET_CLIENT_LIST, data })
export const setClientTypeStats = (data) => ({ type: SET_CLIENTS_TYPE_STATS, data })
export const setClient = (data) => ({ type: SET_CLIENT, data })
export const setClientUsers = (data) => ({ type: SET_CLIENT_USERS, data })
export const setClientId = (id) => ({ type: SET_CLIENT_ID, id })
export const setClientConventions = (data) => ({ type: SET_CLIENT_CONVENTIONS, data })
export const setClientUsersCount = (data) => ({ type: SET_CLIENT_USERS_COUNT, data })
export const setClientNotes = (data) => ({ type: SET_CLIENT_NOTES, data })
export const setClientOpenAccounts = (data) => ({ type: SET_ClIENT_OPEN_ACCOUNTS, data })
export const setClientKpiStatistics = (data) => ({ type: SET_CLIENT_KPI_STATISTICS, data })
export const setClientErrors = (data) => ({ type: SET_CLIENT_ERROS, data })

export const getClientList = (searchTerm, status, type, page, size, sort, isAuthRequired) => (dispatch) => {
    axios.get(`/back-hia/api/v1/clients?query=${searchTerm}&status=${status}&type=${type}${sort.map(element => (`&sort=${element.type},${element.direction}`)).join('')}&page=${page}&size=${size}`, {
        headers: {
            'Content-Type': 'application/json',
            'auth': isAuthRequired
        },

    }).then(
        (response) => {
            if (response.status === 200) {
                dispatch(setClientList(response.data));
                dispatch(getClientsTypeStats(searchTerm, status, type, isAuthRequired))
            }
        }).catch(function (error) {
            console.log('error')
        })

}

export const addClient = (payload, isAuthRequired) => (dispatch) => {
    axios.post("/back-hia/api/v1/clients", payload, {

        headers: {
            'Content-Type': 'application/json',
            'Acces-Control-Allow-Origin': '*',
            'auth': isAuthRequired
        },

    }).then((response) => {
        if (response.status === 200) {
            dispatch(setClientId(response.data.id))
            dispatch(setClientErrors())
        }
    }).catch(function (error) {
        if (error.response.data) {
            dispatch(setClientErrors(error.response.data.errors[0].message))
        }
    })
}

export const getClientsTypeStats = (searchTerm, status, type, isAuthRequired) => (dispatch) => {
    axios.get(`/back-hia/api/v1/clients/stat-status-type?query=${searchTerm}`, {
        headers: {
            'Content-Type': 'application/json',
            'auth': isAuthRequired
        },

    }).then(
        (response) => {
            if (response.status === 200) {
                dispatch(setClientTypeStats(response.data))
            }
        }).catch(function (error) {
            console.log('error')
        })
}

export const getClientUsersList = (clientId, term, accountStatus, conventionStatus, searchType, searchLevel, sort, page, size, isAuthRequired) => (dispatch) => {
    axios.get(`/back-hia/api/v1/${clientId}/accounts/search?query=${term}&accountStatus=${accountStatus}&conventionStatus=${conventionStatus}&level=${searchLevel}&type=${searchType}${sort.map(element => (`&sort=${element.type},${element.direction}`)).join('')}&page=${page}&size=${size}`, {
        headers: {
            'Content-Type': 'application/json',
            'auth': isAuthRequired
        },

    }).then(
        (response) => {
            if (response.status === 200) {
                dispatch(setClientUsers(response.data));
                dispatch(getClientUsersCount(clientId, term, searchLevel, searchType, isAuthRequired))
            }
        }).catch(function (error) {
            console.log('error')
        })

}

export const getClientById = (clientId, isAuthRequired) => (dispatch) => {
    axios.get(`/back-hia/api/v1/clients/${clientId}`, {
        headers: {
            'Content-Type': 'application/json',
            'auth': isAuthRequired
        },

    }).then(
        (response) => {
            if (response.status === 200) {
                dispatch(setClient(response.data))
                dispatch(setLoadingStatus(false))
            }
        }).catch(function (error) {
            console.log('error')
        })
}

export const getClientConventions = (clientId, level, status, sort, isAuthRequired) => (dispatch) => {
    axios.get(`/back-hia/api/v1/convention-families/clients/${clientId}?securityLevel=${level}&active=${status}${sort.map(element => (`&sort=${element.type},${element.direction}`)).join('')}`, {
        headers: {
            'Content-Type': 'application/json',
            'auth': isAuthRequired
        },

    }).then(
        (response) => {
            if (response.status === 200) {
                dispatch(setClientConventions(response.data));
            }
        }).catch(function (error) {
            console.log('error')
        })

}

export const getClientUsersCount = (idClient, searchTerm, searchLevel, searchType, isAuthRequired) => (dispatch) => {
    axios.post(`/back-hia/api/v1/${idClient}/accounts/stat?query=${searchTerm}&level=${searchLevel}&type=${searchType}`, [], {

        headers: {
            'Content-Type': 'application/json',
            'Acces-Control-Allow-Origin': '*',
            'auth': isAuthRequired
        },

    }).then((response) => {
        if (response.status === 200) {
            dispatch(setClientUsersCount(response.data))
        }
    }).catch(function (error) {
        console.log('koAdd')
    })
}
export const updateClient = (payload, isAuthRequired) => (dispatch) => {

    axios.put("/back-hia/api/v1/clients",
        payload,
        {
            headers: {
                'Content-Type': 'application/json',
                'Acces-Control-Allow-Origin': '*',
                'auth': isAuthRequired

            },

        }).then((response) => {
            if (response.status === 200) {

                dispatch(getClientById(payload.id, isAuthRequired))
            }
        }).catch(function (error) {
            if (error.response.data && error.response.data.errors) {
                dispatch(setClientErrors(error.response.data.errors[0].message))
            }
        })
}

export const getClientNotes = (idClient, isAuthRequired) => (dispatch) => {
    axios.get(`/back-hia/api/v1/clients/${idClient}/note`, {

        headers: {
            'Content-Type': 'application/json',
            'Acces-Control-Allow-Origin': '*',
            'auth': isAuthRequired
        },

    }).then((response) => {
        if (response.status === 200) {
            dispatch(setClientNotes(response.data))
        }
    }).catch(function (error) {
        console.log('koAdd')
    })
}

export const getClientOpenAccounts = (idClient, datefrom, dateTo, isAuthRequired) => (dispatch) => {
    axios.get(`/back-hia/api/v1/${idClient}/statistics/opened-account?dateFrom=${datefrom}&dateTo=${dateTo}`, {

        headers: {
            'Content-Type': 'application/json',
            'Acces-Control-Allow-Origin': '*',
            'auth': isAuthRequired
        },

    }).then((response) => {
        if (response.status === 200) {
            dispatch(setClientOpenAccounts(response.data))
        }
    }).catch(function (error) {
        console.log('koAdd')
    })
}

export const updateClientConventions = (payload, clientId, isAuthRequired) => (dispatch) => {

    axios.put(`/back-hia/api/v1/convention-families/clients/${clientId}`,
        payload,
        {
            headers: {
                'Content-Type': 'application/json',
                'Acces-Control-Allow-Origin': '*',
                'auth': isAuthRequired

            },

        }).then((response) => {
            if (response.status === 200) {

                dispatch(getClientConventions(clientId, '', '', [], isAuthRequired))
            }
        }).catch(function (error) {
            console.log('ko')
        })
}

export const updateClientNote = (payload, clientId, isAuthRequired) => (dispatch) => {

    axios.put(`/back-hia/api/v1/clients/${clientId}/note`,
        payload.note,
        {
            headers: {
                'Content-Type': 'application/json',
                'Acces-Control-Allow-Origin': '*',
                'auth': isAuthRequired

            },

        }).then((response) => {
            if (response.status === 200) {

                dispatch(getClientNotes(clientId, isAuthRequired))
            }
        }).catch(function (error) {
            console.log('ko')
        })
}
export const getClientKpiStatistics = (idClient, dateFrom, dateTo, kpiFilter, isAuthRequired) => (dispatch) => {
    axios.get(`/back-hia/api/v1/${idClient}/statistics/kpi?dateFrom=${dateFrom}&dateTo=${dateTo}&kpiType=${kpiFilter}`, {

        headers: {
            'Content-Type': 'application/json',
            'Acces-Control-Allow-Origin': '*',
            'auth': isAuthRequired
        },

    })
        .then((response) => {
            if (response.status === 200) {
                dispatch(setClientKpiStatistics(response.data))
            }

        }).catch(function (error) {

        })
}
import { loadToken, saveToken, loadAckToken } from "../utils/sessionStorage";
import axios from 'axios'
import qs from 'querystring'
import keycloak from '../Keycloak'
export const SET_LOADING_TRUE = 'SET_LOADING_TRUE';
export const setLoadingTrue = () => ({ type: SET_LOADING_TRUE })
export const SET_LOADING_STATUS = 'SET_LOADING_STATUS';
export const setLoadingStatus = (status) => ({ type: SET_LOADING_STATUS, status })

export const SET_APPLICATION_LANGUAGE = 'SET_APPLICATION_LANGUAGE';
export const setApplicationLanguage = (language) => ({ type: SET_APPLICATION_LANGUAGE, language })


axios.interceptors.request.use(function (config) {
    console.log("interceptor is called");
    console.log("config.headers.auth : ", config.headers.auth);
    if (config.headers.auth === true) {
        config.headers.Authorization = 'Bearer ' + keycloak.token

    }
    else {
        const ackToken = loadAckToken();
        console.log("loading ackToken :", ackToken);
        if (ackToken){
            config.headers.Authorization = 'Bearer ' + ackToken.access_token
        }
    }
    return config;
});

const createAxiosResponseInterceptor = () => {
    const params = {
        grant_type: 'refresh_token',
        refresh_token: loadToken().refresh_token
    }
    const interceptor = axios.interceptors.response.use(
        response => response,
        error => {
            // Reject promise if usual error
            if (error.response.status !== 401) {
                return Promise.reject(error);
            }

            /* 
             * When response code is 401, try to refresh the token.
             * Eject the interceptor so it doesn't loop in case
             * token refresh causes the 401 response
             */
            axios.interceptors.response.eject(interceptor);

            return axios.post('/hia-oauth2/oauth/token', qs.stringify(params), {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Acces-Control-Allow-Origin': '*'
                },

            }).then(response => {
                // dispatch(setToken(response.data))
                saveToken(response.data)
                error.response.config.headers['Authorization'] = 'Bearer ' + response.data.access_token;
                return axios(error.response.config);
            }).catch(error => {

                return Promise.reject(error);
            }).finally(createAxiosResponseInterceptor);
        }
    );
}
createAxiosResponseInterceptor();
import React from 'react';
import utf8 from 'utf8'

export const dateOfcounter = (value) => {
    const date = new Date();
    const minutes = date.setSeconds(date.getSeconds() + value * 60);
    const dateFinal = new Date(minutes);
    return dateFinal;
}

export const renderer = ({ minutes, seconds, completed }) => {

    let minute = minutes
    let second = seconds
    if (!completed) {


        if (minute < 10) {
            minute = `0${minute}`
        }
        if (second < 10) {
            second = `0${second}`
        }

        return (<span style={{ color: "#fff" }}>{minute}:{second}</span>);
    }

    return null;
};

export const customizeConventionDisplay = (text) => {
    console.log("text : ", text);
    const text2 = text.replaceAll("ï¿½", "è");
    const char = text2.split(',').join(',<br />')
    const stripped = char.split('.').join('.<br />')
    return stripped;
}

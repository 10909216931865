import axios from 'axios';
import { GRANT_TYPE, CLIENT_ID, CLIENT_SECRET } from '../utils/tokenConfig';
import { saveAuthenticationState, saveToken, saveDecodedToken } from '../utils/sessionStorage';
import qs from 'querystring'
import { SERVER_ERROR, INVALID_LOGIN } from '../utils/constantDefinition';
import { isInRole } from '../utils/models';
export const SET_TOKEN = 'SET_TOKEN';
export const SET_LOGGED_IN = 'SET_LOGGED_IN';
export const LOGOUT = 'LOGOUT';
export const SET_ERRORS = 'SET_ERRORS'

const parseJwt = (token) => {
    var base64Url = token.split('.')[1];
    var base64 = decodeURIComponent(atob(base64Url).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(base64);
};


export const setToken = (data) => ({ type: SET_TOKEN, data });
export const setIsLoggedIn = (value) => ({ type: SET_LOGGED_IN, value })
export const logout = () => ({ type: LOGOUT })
export const setErrors = error => ({ type: SET_ERRORS, error })

export const validateLogin = (payload) => (dispatch) => {


    const params = {
        username: payload.username,
        password: payload.password,
        grant_type: GRANT_TYPE,
        client_id:CLIENT_ID,
        client_secret:CLIENT_SECRET,
        scope: "openid"
    }

    axios.post("/auth/realms/hia-realm/protocol/openid-connect/token", qs.stringify(params), {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Access-Control-Allow-Origin': '*'
        },

    }).then((response) => {
        if (response.error === undefined) {
            if(isInRole('HIA_ADMIN',parseJwt(response.data.access_token).realm_access.roles)){
                saveDecodedToken(parseJwt(response.data.access_token))
                dispatch(setToken(response.data))
                dispatch(setIsLoggedIn(true))
                saveAuthenticationState(true)
                saveToken(response.data)
                dispatch(setErrors())
            }
            else if (response.error === "invalid_grant"){
                dispatch(setErrors(INVALID_LOGIN))
            }
           
        }
    }).catch(function (error) {
        if (error.response.status === 400) {
            dispatch(setErrors(INVALID_LOGIN))
        }
        else
            dispatch(setErrors(SERVER_ERROR))

    })
}


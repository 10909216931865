import React, { useEffect, useState, useCallback } from "react";
import { compose, map } from "ramda";
import { connect } from "react-redux";
import { withRouter, Route, Switch, Redirect } from "react-router-dom";
import { createStructuredSelector } from "reselect";
import Grid from "@material-ui/core/Grid";
import Helmet from "react-helmet";
import LoadingSpin from "react-loading-spin";

import { IntlProvider } from "react-intl";
import { addLocaleData } from "react-intl";
import { isInRole } from "../../utils/models";

import enLocaleData from "react-intl/locale-data/en";
import frLocaleData from "react-intl/locale-data/fr";
import withRoutes from "../../hoc/routes";
import messages_en from "../../utils/messages/en.json";
import messages_fr from "../../utils/messages/fr.json";
import { getLanguage } from "../../selectors/language";
import { getIsLoggedInStatus } from "../../selectors/authentication";
import Header from "./Header";
import Auth from "../Auth";
import * as authenticationActions from "../../actions/authentication";
import { bindActionCreators } from "redux";
import FirstHeader from "./FirstHeader";
import { withStyles } from "@material-ui/core";
import { getAcknowledgeStatus } from "../../selectors/conventions";
import classNames from "classnames";

import keycloakState from "../../Keycloak";

addLocaleData(enLocaleData);
addLocaleData(frLocaleData);

const messages = {
  fr: messages_fr,
  en: messages_en,
};

const makeAuthRoute =
  ({ isAuthRequired }, route) =>
  (props) => {
    if (isAuthRequired(route)) {
      return (
        <Auth route={route}>
          <route.component {...props} />
        </Auth>
      );
    }
    return <route.component {...props} />;
  };
const defaultRoute = (routes) => {
  const { getDefaultRoute } = routes;
  const route = getDefaultRoute();
  if (route) return <Redirect to={route.path} />;
};
let imgUrl = require("../../images/fond.png");
const styles = (theme) => ({
  ackRoot: {
    height: "100%",
    backgroundImage: "url(" + imgUrl + ")",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "right",
    backgroundSize: "auto 100%",
  },
  root: {
    height: "100%",
  },
  container: {
    position: "relative",
    width: "100%",
    minWidth: "1300px",
    overflowX: "hidden",
    overflowY: "auto",
  },
});
const defaultStyle = {
  style:
    " font-family: Heebo ; background-color: transparent; color: #505050,height:100%,margin:0",
};
const acknowledgmentStyle = {
  style: " background-color: #5071D4; color: #505050,height:100%,margin:0",
};
const App = ({
  language,
  routes,
  location,
  isLoggedIn,
  actions,
  classes,
  acknowledgeStatus,
}) => {
  const [isAutenticated, setIsAutenticated] = useState(false);
  const [keycloak, setKeycloak] = useState(keycloakState);
  const loadConfig = useCallback(() => {
    keycloak
      .init({
        onLoad: "login-required",
        bearerExcludedUrls: ["/ack-of-receipt"],
      })
      .then((authenticated) => {
        setKeycloak(keycloak);
        setIsAutenticated(authenticated);
      });
  }, [keycloak]);
  useEffect(() => {
    console.log("path: ", window.location.pathname);
    if (!window.location.pathname.startsWith("/ack")) loadConfig();
  }, [loadConfig]);
  if (keycloak) {
    if (isAutenticated) {
      console.log("token :", keycloak.token);
      if (isInRole("HIA_ADMIN", keycloak.realmAccess.roles))
        return (
          <Grid
            container
            className={
              location.pathname.startsWith("/ack")
                ? !acknowledgeStatus
                  ? classes.ackRoot
                  : classes.root
                : classNames(classes.container)
            }
          >
            <Helmet
              bodyAttributes={
                acknowledgeStatus && location.pathname.startsWith("/ack")
                  ? acknowledgmentStyle
                  : defaultStyle
              }
            />

            <IntlProvider
              locale={language ? language : "en"}
              messages={messages[language ? language : "en"]}
            >
              <Grid item xs={12}>
                {!location.pathname.startsWith("/ack") && (
                  <React.Fragment>
                    {<FirstHeader actions={actions} />}
                    <Header isLoggedIn={isLoggedIn} />
                  </React.Fragment>
                )}

                <Switch>
                  {map(
                    (route) => (
                      <Route
                        key={route.path}
                        exact={route.exact}
                        path={route.path}
                        render={makeAuthRoute(routes, route)}
                      />
                    ),
                    routes.getRoutes()
                  )}
                  {defaultRoute(routes)}
                </Switch>
              </Grid>
            </IntlProvider>
          </Grid>
        );
      else keycloak.logout();
    } else if (window.location.pathname.startsWith("/ack"))
      return (
        <Grid
          container
          className={
            location.pathname.startsWith("/ack")
              ? !acknowledgeStatus
                ? classes.ackRoot
                : classes.root
              : classNames(classes.container)
          }
        >
          <Helmet
            bodyAttributes={
              acknowledgeStatus && location.pathname.startsWith("/ack")
                ? acknowledgmentStyle
                : defaultStyle
            }
          />

          <IntlProvider
            locale={language ? language : "en"}
            messages={messages[language ? language : "en"]}
          >
            <Grid item xs={12}>
              {!location.pathname.startsWith("/ack") && (
                <React.Fragment>
                  {<FirstHeader actions={actions} />}
                  <Header isLoggedIn={isLoggedIn} />
                </React.Fragment>
              )}

              <Switch>
                {map(
                  (route) => (
                    <Route
                      key={route.path}
                      exact={route.exact}
                      path={route.path}
                      render={makeAuthRoute(routes, route)}
                    />
                  ),
                  routes.getRoutes()
                )}
                {defaultRoute(routes)}
              </Switch>
            </Grid>
          </IntlProvider>
        </Grid>
      );
    else
      return (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            marginTop: "-50px",
            marginLeft: "-50px",
            width: "100px",
            height: "100px",
          }}
        >
          <LoadingSpin
            duration="2s"
            width="15px"
            timingFunction="ease-in-out"
            direction="alternate"
            size="200px"
            primaryColor="blue"
            secondaryColor="white"
            numberOfRotationsInAnimation={2}
          />
        </div>
      );
  }
};
const mapStateToProps = createStructuredSelector({
  language: getLanguage,
  isLoggedIn: getIsLoggedInStatus,
  acknowledgeStatus: getAcknowledgeStatus,
});
const actions = {
  logout: authenticationActions.logout,
};
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

const enhance = compose(
  withStyles(styles),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withRoutes
);
export default enhance(App);
